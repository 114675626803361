import type {
  IConditionalSetValueModel,
  IDataService,
  IRules,
  IStep,
  NaicsRange,
  PrimaryAttributes,
  SyncOrFillEligibleAttributes
} from "@/models/orchestration";
import {
  makeRandomString,
  wordsFirstLetterToUpper
} from "@/helpers/formatting";
import {
  COLORS,
  SERVICES_COLOR_CODES,
  TYPE_BOOLEAN,
  TYPE_LESS_THAN,
  TYPE_LESS_THAN_OR_EQUAL,
  TYPE_GREATER_THAN,
  TYPE_GREATER_THAN_OR_EQUAL,
  TYPE_EQUAL,
  TYPE_NOT_EQUAL,
  TYPE_RANGE,
  TIME_IN_BUSINESS_SERVICE_EXCEPTIONS,
  TYPE_NAICS_IN_RANGE,
  TYPE_NAICS_NOT_IN_RANGE,
  TYPE_IN_FLEXIBLE,
  TYPE_NOT_IN_FLEXIBLE
} from "@/helpers/constants";
import { useI18n } from "vue-i18n";
import type { ConditionType } from "@/models/common";

export const getDoStepBlueprint = (data: IDataService): IStep => {
  return {
    id: makeRandomString(15),
    attributes: [],
    title: data.title,
    provider: data.provider,
    outcomes: []
  };
};

export const getServiceColor = (service: string | null | undefined): string => {
  if (!service) {
    return "rgb(0, 0, 0)";
  }
  return `rgb(${
    COLORS[SERVICES_COLOR_CODES[service as keyof typeof SERVICES_COLOR_CODES]]
  })`;
};

export const validateDataOrchestrationPayload = (steps: IStep[]): string[] => {
  const errors: string[] = [];

  /** check if at least one step was added */
  if (!steps.length) {
    errors.push("The template must include at least one step");
  }
  steps.forEach((step) => {
    const stepTitle = step.title || step.id;
    /** check if all steps have at least one outcome */
    if (!step.outcomes.length) {
      errors.push(`${stepTitle} step must have at least one outcome`);
    }

    if (step.attributes?.length) {
      step.attributes.forEach((attribute) => {
        if (!attribute.options) {
          return;
        }
        const negativeOrDecimalNumericValues = Object.values(
          attribute.options
        ).filter(
          (val) => typeof val === "number" && (val < 0 || val % 1 !== 0)
        );
        if (negativeOrDecimalNumericValues.length) {
          errors.push(
            `${stepTitle} step options can't have negative or decimal numeric values`
          );
        }
      });
    } else {
      /** check if all steps have at least one attribute */
      errors.push(`${stepTitle} step must have at least one attribute`);
    }

    /** check if all step outcomes have at least one check and if each check has condition */
    const argumentError = (step: string, outcome: string) =>
      `Please add conditional argument for ${step} step, ${outcome} outcome.`;
    const valuesError = (step: string, outcome: string) =>
      `Please provide conditional argument values for ${step} step, ${outcome} outcome.`;

    step.outcomes.forEach((outcome) => {
      if (!outcome.checks?.length) {
        return errors.push(
          argumentError(stepTitle, wordsFirstLetterToUpper(outcome.outcome))
        );
      }

      outcome.checks.forEach((check) => {
        if (!check.conditions?.length) {
          return errors.push(
            argumentError(stepTitle, wordsFirstLetterToUpper(outcome.outcome))
          );
        }

        check.conditions.forEach((checkCondition) => {
          if (!checkCondition.conditions?.length) {
            errors.push(
              valuesError(stepTitle, wordsFirstLetterToUpper(outcome.outcome))
            );
          }
        });
      });
    });
  });

  return errors;
};

export const getRuleTypes = (rules: IRules | undefined, argId?: string) => {
  if (!rules) {
    return {};
  }
  const { t } = useI18n();
  const OPTIONS_WITH_SYMBOLS_OR_MODIFICATIONS: Record<string, string> = {
    [TYPE_LESS_THAN]: t(`FIELD_DICTIONARY.${TYPE_LESS_THAN}`),
    [TYPE_LESS_THAN_OR_EQUAL]: t(`FIELD_DICTIONARY.${TYPE_LESS_THAN_OR_EQUAL}`),
    [TYPE_GREATER_THAN]: t(`FIELD_DICTIONARY.${TYPE_GREATER_THAN}`),
    [TYPE_GREATER_THAN_OR_EQUAL]: t(
      `FIELD_DICTIONARY.${TYPE_GREATER_THAN_OR_EQUAL}`
    ),
    [TYPE_EQUAL]: t(`FIELD_DICTIONARY.${TYPE_EQUAL}`),
    [TYPE_NOT_EQUAL]: t(`FIELD_DICTIONARY.${TYPE_NOT_EQUAL}`),
    [TYPE_BOOLEAN]: t(`FIELD_DICTIONARY.${TYPE_BOOLEAN}`),
    [TYPE_IN_FLEXIBLE]: t("FIELD_DICTIONARY.in"),
    [TYPE_NOT_IN_FLEXIBLE]: t("FIELD_DICTIONARY.not_in")
  };

  const [, actualRules] = rules.type.split(":");
  const typesArray = actualRules.split(",");
  const shouldAppendRangeType =
    !getIsTimeInBusiness(argId || "") &&
    [TYPE_LESS_THAN_OR_EQUAL, TYPE_GREATER_THAN_OR_EQUAL].every((rule) =>
      typesArray.includes(rule)
    );

  const types = typesArray.reduce<Record<string, string>>(
    (acc, curr) => ({
      ...acc,
      [curr]:
        OPTIONS_WITH_SYMBOLS_OR_MODIFICATIONS[curr] ||
        wordsFirstLetterToUpper(curr, "_")
    }),
    {}
  );

  if (!shouldAppendRangeType) {
    return types;
  }

  return {
    ...types,
    [TYPE_RANGE]: t(`FIELD_DICTIONARY.${TYPE_RANGE}`)
  };
};

export const getHasOneOrLessOptions = (types: Record<string, string>) => {
  const options = types || {};
  return Object.keys(options).length <= 1;
};

/**
 * Map the given DO attribute id to a sync context attribute.
 *
 * For example, 'sentilink_completion_confidence_score_dob' supports syncing
 * date of birth on the primary owner by specifying 'dob' in the list of attributes
 * on the sync context.
 *
 * @param {string} attribute The DO attribute id
 * @returns {string|null}
 */
export const syncContextAttribute = (attribute: string) =>
  SYNC_MAP[attribute.toLowerCase()] || null;

const SYNC_MAP: Record<string, string> = {
  sentilink_dob_completion_dob: "dob",
  sentilink_ssn_completion_confidence_score_ssn: "ssn",
  middesk_business_search_name_search: "business_name"
};

const SYNC_OR_FILL_MAP: Record<string, SyncOrFillEligibleAttributes[]> = {
  address_line: [
    "persona_dl_address",
    "persona_pp_address",
    "persona_id_address",
    "clear_id_confirm_business_street_match_score",
    "clear_id_confirm_person_street_match_score",
    "lexis_nexis_kyc_has_street_number",
    "lexis_nexis_kyc_has_street"
  ],
  address_line2: [
    "persona_dl_address_line2",
    "persona_pp_address_line2",
    "persona_id_address_line2"
  ],
  city: [
    "persona_dl_city",
    "persona_pp_city",
    "persona_id_city",
    "clear_id_confirm_business_city_match_score",
    "clear_id_confirm_person_city_match_score",
    "lexis_nexis_kyc_has_city"
  ],
  state: [
    "persona_dl_state",
    "persona_pp_state",
    "persona_id_state",
    "clear_id_confirm_business_state_match_score",
    "clear_id_confirm_person_state_match_score",
    "lexis_nexis_kyc_has_state"
  ],
  zip: [
    "persona_dl_zip",
    "persona_pp_zip",
    "persona_id_zip",
    "clear_id_confirm_business_zip_code_match_score",
    "clear_id_confirm_person_zip_code_match_score",
    "lexis_nexis_kyc_has_zip_code"
  ],
  telephone: [
    "clear_id_confirm_business_phone_number_match_score",
    "clear_id_confirm_person_phone_number_match_score",
    "lexis_nexis_kyc_report_has_telephone",
    "lexis_nexis_kyb_search_has_phone_number",
    "lexis_nexis_contact_card_report_has_phone_number"
  ],
  ein: ["clear_id_confirm_business_fein_match_score"],
  dob: [
    "persona_dl_date_of_birth",
    "persona_pp_date_of_birth",
    "persona_id_date_of_birth",
    "lexis_nexis_kyc_has_date_of_birth"
  ],
  first_name: [
    "persona_dl_first_name",
    "persona_pp_first_name",
    "persona_id_first_name",
    "clear_id_confirm_business_officer_agent_first_name_match_score",
    "lexis_nexis_kyc_has_first_name"
  ],
  last_name: [
    "persona_dl_last_name",
    "persona_pp_last_name",
    "persona_id_last_name",
    "clear_id_confirm_business_officer_agent_last_name_match_score",
    "lexis_nexis_kyc_has_last_name"
  ],
  full_address: [
    "persona_dl_full_address",
    "persona_id_full_address",
    "persona_pp_full_address",
    "lexis_nexis_email_search_has_address",
    "lexis_nexis_kyc_report_full_address",
    "lexis_nexis_kyc_report_has_residential_address",
    "lexis_nexis_kyc_report_has_business_address",
    "lexis_nexis_kyb_search_has_address",
    "lexis_nexis_contact_card_report_has_address",
    "lexis_nexis_contact_card_report_has_owner_address"
  ],
  naics_code: [
    "dnb_ci_l2_naics_code",
    "middesk_naics_code",
    "experian_business_facts_naics_code",
    "clear_risk_inform_business_report_naics_code",
    "lexis_nexis_kyb_report_naics_code"
  ],
  sic_code: [
    "dnb_ci_l2_sic_code",
    "experian_business_facts_sic_code",
    "clear_risk_inform_business_report_sic_code",
    "lexis_nexis_kyb_report_sic_code"
  ],
  additional_business_stated_average_balance: [
    "ocrolus_cfa_average_daily_balance",
    "ocrolus_cfa_v2_average_daily_balance",
    "moneythumb_cfa_average_balance"
  ],
  funding_actual_average_monthly_revenue: [
    "ocrolus_cfa_average_estimated_revenue",
    "ocrolus_cfa_v2_revenue_by_month",
    "moneythumb_cfa_total_credits"
  ],
  email: [
    "lexis_nexis_email_search_has_email",
    "lexis_nexis_kyc_report_has_email",
    "lexis_nexis_kyb_search_has_email"
  ],
  additional_business_operating_expense: [
    "moneythumb_cfa_monthly_expense",
    "ocrolus_cfa_v2_expense_by_month",
    "ocrolus_cfa_withdrawal_sum"
  ],
  full_name: [
    "lexis_nexis_kyb_search_has_owner_name",
    "lexis_nexis_contact_card_report_has_owner_name"
  ],
  dot_number: ["fmcsa_usdot_number"],
  usdot_status: ["fmcsa_usdot_status"],
  entity_type: ["fmcsa_entity_type"],
  power_units: ["fmcsa_power_units"],
  operation_classification: ["fmcsa_operation_classification"],
  cargo_carried: ["fmcsa_cargo_carried"],
  authority_status_broker: ["fmcsa_authority_status_broker"],
  authority_status_common: ["fmcsa_authority_status_common"],
  insurance_on_file: ["fmcsa_insurance_on_file"],
  insurance_required: ["fmcsa_insurance_required"],
  additional_telephones: [
    "clear_risk_inform_business_search_add_phone_numbers_to_business",
    "clear_risk_inform_business_report_add_phone_numbers_to_business",
    "clear_id_confirm_business_add_phone_numbers_to_business"
  ]
};

const PRIMARY_ATTRIBUTES_MAP: Record<string, PrimaryAttributes[]> = {
  full_address: [
    "persona_dl_full_address",
    "persona_pp_full_address",
    "persona_id_full_address",
    "lexis_nexis_email_search_has_address",
    "lexis_nexis_kyc_report_full_address",
    "lexis_nexis_kyc_report_has_residential_address",
    "lexis_nexis_kyc_report_has_business_address",
    "lexis_nexis_kyb_search_has_address",
    "lexis_nexis_contact_card_report_has_address",
    "lexis_nexis_contact_card_report_has_owner_address"
  ],
  email: [
    "lexis_nexis_email_search_has_email",
    "lexis_nexis_kyc_report_has_email",
    "lexis_nexis_kyb_search_has_email"
  ],
  telephone: [
    "lexis_nexis_kyc_report_has_telephone",
    "lexis_nexis_kyb_search_has_phone_number",
    "lexis_nexis_contact_card_report_has_phone_number"
  ],
  additional_telephones: [
    "clear_risk_inform_business_search_add_phone_numbers_to_business",
    "clear_risk_inform_business_report_add_phone_numbers_to_business",
    "clear_id_confirm_business_add_phone_numbers_to_business"
  ]
};

export const syncOrFillContextAttribute = (
  attribute: SyncOrFillEligibleAttributes
) => {
  return (
    Object.keys(SYNC_OR_FILL_MAP).find((key) =>
      SYNC_OR_FILL_MAP[key].includes(attribute)
    ) || null
  );
};

export const getPrimaryContextAttribute = (attribute: PrimaryAttributes) => {
  return (
    (
      Object.keys(
        PRIMARY_ATTRIBUTES_MAP
      ) as (keyof typeof PRIMARY_ATTRIBUTES_MAP)[]
    ).find((key) => PRIMARY_ATTRIBUTES_MAP[key].includes(attribute)) || null
  );
};

/**
 * Is the given DO attribute id a DO attribute that supports syncing?
 *
 * @param {string} attribute
 * @returns {boolean}
 */
export const supportsSyncContext = (attribute: string) =>
  syncContextAttribute(attribute) !== null;

export const supportsSyncOrFillContext = (
  attribute: SyncOrFillEligibleAttributes
) => syncOrFillContextAttribute(attribute) !== null;

export const supportsPrimaryContext = (attribute: PrimaryAttributes) =>
  getPrimaryContextAttribute(attribute) !== null;

export const getIsTimeInBusiness = (id: string) => {
  return (
    TIME_IN_BUSINESS_SERVICE_EXCEPTIONS.every(
      (exceptionId) => !id.startsWith(exceptionId)
    ) && id.endsWith("_time_in_business")
  );
};

export const filterInvalidValueOptions = (
  options: Record<string, string>
): Record<string, string> =>
  Object.fromEntries(
    Object.entries(options).filter(([key]) => {
      return !["consumer_score_models_levels"].includes(key);
    })
  );

export const typeIsNaicsCodeRange = (type: ConditionType) =>
  type === TYPE_NAICS_IN_RANGE || type === TYPE_NAICS_NOT_IN_RANGE;

export const haveNaicsRangeValues = (condition: IConditionalSetValueModel) => {
  if (
    !condition?.value ||
    typeof condition !== "object" ||
    Array.isArray(condition)
  ) {
    return false;
  }
  const value = condition.value as NaicsRange;
  return "from" in value && "through" in value && "digits" in value;
};

export const isNullishAttributeWithContext = (id: string) =>
  id.endsWith("add_phone_numbers_to_business");
