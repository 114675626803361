<template>
  <div
    class="pt-6 pb-2 px-2 space-y-3 flex flex-col h-[calc(100%-53px)] overflow-y-auto rounded-b-md"
  >
    <loader :is-loading="loading" />
    <div class="email-header-row">
      <div class="left">{{ $t("COMMON.TEMPLATE") }}:</div>
      <div class="right">
        <tooltip
          ref="templatesTooltip"
          placement="bottom-start"
          trigger="click"
          hide-on-click="toggle"
          :on-click-outside="hideDropdown"
          :append-to="body"
          :arrow="false"
          sticky="reference"
          theme="none"
          interactive
          :popper-options="popperOptions"
        >
          <template #default>
            <lf-pill
              size="sm"
              class="uppercase font-bold ml-2 cursor-pointer h-[26px]"
              color-index="15"
            >
              <div class="space-x-2 flex items-center">
                <div data-cy="template-name" class="w-30 truncate text-left">
                  <span>{{ selectedTemplate?.name || "" }}</span>
                </div>
                <icon-base
                  :icon="IconArrowUp"
                  height="10"
                  width="10"
                  :class="{
                    'rotate-180 -translate-y-1':
                      !templatesTooltip?.state.isVisible
                  }"
                />
              </div>
            </lf-pill>
          </template>
          <template #content>
            <div class="p-2 w-100 bg-white shadow-lg rounded-md">
              <div class="sticky pb-2">
                <search-input
                  v-model="search"
                  data-cy="email-template-search"
                  class="w-full h-10"
                  no-padding
                  no-margin
                />
              </div>
              <ul
                data-cy="email-templates"
                class="px-1 text-sm text-headline overflow-y-auto overflow-x-hidden max-h-100 h-auto"
              >
                <li
                  v-for="(template, index) in filteredTemplatesOptions"
                  :key="template.id"
                  ref="templateListRef"
                  v-tooltip="{
                    content: template.name,
                    onShow: () => isTemplateOptionTruncated[index]
                  }"
                  class="py-2 px-3 rounded hover:bg-gray-200 whitespace-nowrap cursor-pointer text-xs font-semibold truncate"
                  @click="selectTemplate(template)"
                >
                  {{ template.name }}
                </li>
              </ul>
            </div>
          </template>
        </tooltip>
      </div>
    </div>
    <div class="email-header-row">
      <div class="left">{{ $t("COMMON.FROM") }}:</div>
      <div class="right">
        <div class="w-full">
          <lf-dropdown
            v-on-click-outside="() => (showSendersDropdown = false)"
            :value="isSendAsBulk ? sender?.type : sender?.value"
            :options="senders"
            class="from-dropdown"
            :class="{ 'from-dropdown-single': !isSendAsBulk }"
            data-cy="email-template-from"
            :capitalize="false"
            :toggle-from-parent="showSendersDropdown"
            full-width
          >
            <template #toggle-button="{ value }">
              <button
                class="relative w-full pl-4 pr-8 text-left text-sm rounded py-1 flex items-center dropdown-button focus:outline-none h-10"
                :class="
                  showSendersDropdown
                    ? 'border-primary hover:border-primary border-2'
                    : 'border hover:border-gray-300'
                "
                @click.prevent="showSendersDropdown = !showSendersDropdown"
              >
                <span>
                  {{ value }}
                </span>
                <span
                  v-if="sender?.type && !isSendAsBulk"
                  class="text-xxs uppercase text-label font-semibold ml-3 bg-disabled p-1 rounded"
                >
                  {{ SENDER_TYPE_MAP[sender.type] }}
                </span>
                <span
                  class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer text-gray-400"
                >
                  <icon-base
                    height="6"
                    width="10"
                    icon="dropdown-arrow"
                    :class="{ 'transform rotate-180': showSendersDropdown }"
                  />
                </span>
              </button>
            </template>
            <template #listItem="{ option, index }">
              <li
                :data-cy="option"
                class="p-2 hover:bg-gray-200 flex justify-start overflow-auto w-full dropdown-option"
                role="option"
                @click.stop="handleUpdateSender(option as string | number)"
              >
                {{ option }}
                <span
                  v-if="sender?.type && !isSendAsBulk"
                  class="text-xxs uppercase text-label font-semibold ml-3 bg-disabled p-1 rounded"
                >
                  {{ getSenderTypeForIndex(index) }}
                </span>
              </li>
            </template>
          </lf-dropdown>
          <field-error-message
            v-if="erroredFields.includes('from')"
            :value="$t('COMMON.VALUE_IS_REQUIRED')"
          />
        </div>
      </div>
    </div>
    <div class="email-header-row to">
      <div class="left">{{ $t("COMMON.TO") }}:</div>
      <div class="right">
        <div class="w-full">
          <multiselect-dropdown
            class="w-full multiselect-dropdown"
            data-cy="email-template-to"
            name="to"
            placeholder=""
            :value="selectedRecipients"
            :options="recipientOptions"
            :can-format-option="false"
            is-absolute-positioned
            scrollable-parent="do-service"
            :allow-trim="false"
            @change="handleUpdateRecipients"
          />
          <field-error-message
            v-if="erroredFields.includes('to')"
            :value="$t('COMMON.VALUE_IS_REQUIRED')"
          />
        </div>
      </div>
    </div>
    <div class="email-header-row">
      <div class="left">{{ $t("ORGANIZATION.EMAIL_TEMPLATES.CC") }}:</div>
      <div class="right relative">
        <icon-base
          v-if="ccInputWarning.show"
          v-tooltip="ccInputWarning.text"
          :icon="IconWarning"
          data-cy="cc-warning"
          class="field-warning"
        />
        <lf-email-input-multiple
          :value="ccInput"
          :emails="ccEmails"
          @change="handleCcChange"
        />
      </div>
    </div>
    <div class="email-header-row">
      <div class="left">{{ $t("EMAIL_INTEGRATION.BCC") }}:</div>
      <div class="right relative">
        <icon-base
          v-if="bccInputWarning.show"
          v-tooltip="bccInputWarning.text"
          :icon="IconWarning"
          data-cy="bcc-warning"
          class="field-warning"
        />
        <lf-email-input-multiple
          :value="bccInput"
          :emails="bccEmails"
          @change="handleBccChange"
        />
      </div>
    </div>
    <div class="email-header-row">
      <div class="left">{{ $t("ORGANIZATION.EMAIL_TEMPLATES.SUBJECT") }}:</div>
      <div class="right">
        <div class="w-full">
          <dynamic-field
            :model-value="selectedTemplate?.subject || ''"
            name="subject"
            data-cy="email-template-subject"
            class="email-headless w-full"
            :placeholder="$t('ORGANIZATION.EMAIL_TEMPLATES.SUBJECT')"
            @update:model-value="selectedTemplate.subject = $event"
          />
          <field-error-message
            v-if="erroredFields.includes('subject')"
            :value="$t('COMMON.VALUE_IS_REQUIRED')"
          />
        </div>
      </div>
    </div>
    <div class="email-header-row pl-4">
      <select-email-branding
        v-model="selectedBrandingId"
        :brandings="modalData.customizations"
        has-default
        is-toggleable
      />
    </div>
    <div class="grow relative">
      <dynamic-field
        :model-value="selectedTemplate?.body || ''"
        data-cy="email-template-body"
        class="dynamic-field__body"
        name="body"
        show-toolbar
        toolbar-type="dynamic"
        @update:model-value="selectedTemplate.body = $event"
      />
      <div
        ref="sendButtonBlockRef"
        class="bg-disabled p-2 absolute bottom-0 rounded-bl rounded-br left-0 right-0 border-l border-r border-b"
      >
        <field-error-message
          v-if="erroredFields.includes('body')"
          class="-ml-2"
          :value="$t('COMMON.VALUE_IS_REQUIRED')"
        />
        <div class="flex items-center justify-between">
          <div>
            {{ $t("COMMON.TYPE") }}
            <span class="bg-white px-2 py-1">[</span>
            {{
              $t(
                "ORGANIZATION.EMAIL_TEMPLATES.DYNAMIC_FIELD_SYMBOL_EXPLANATION_SHORT"
              ).toLowerCase()
            }}
          </div>
          <primary-button
            data-cy="send-button"
            id="email-send-button"
            class="send-button"
            @click="sendEmail"
          >
            <icon-base :icon="IconSend" class="text-white" />
          </primary-button>
        </div>
      </div>
    </div>
    <primary-button
      data-cy="send-button-2"
      id="email-send-button"
      class="send-button"
      :class="targetIsVisible ? 'hidden' : 'sticky bottom-0 left-full'"
      @click="sendEmail"
    >
      <icon-base :icon="IconSend" class="text-white" />
    </primary-button>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, toRaw } from "vue";
import type { EmailTemplate } from "@/models/clients";
import type { MultiSelectOption } from "@/models/options";
import type { EmailAddress } from "@/models/emails";
import type { TippyComponent } from "vue-tippy";
import emailService from "@/services/modules/emails";
import useEmailsStore from "@/stores/emails";
import { useElementStatus } from "@/hooks/elements";
import { usePromiseWrapper } from "@/hooks/common";
import { useI18n } from "vue-i18n";
import { useNotification } from "@/hooks/notifications";
import { storeToRefs } from "pinia";
import DynamicField from "@/components/DynamicField.vue";
import MultiselectDropdown from "@/components/ui/inputs/MultiselectDropdown.vue";
import LfEmailInputMultiple from "@/components/ui/inputs/LfEmailInputMultiple.vue";
import IconSend from "@/components/icons/IconSend.vue";
import IconArrowUp from "@/components/icons/IconArrowUp.vue";
import FieldErrorMessage from "@/components/ui/inputs/FieldErrorMessage.vue";
import cloneDeep from "lodash/cloneDeep";
import IconWarning from "@/components/icons/IconWarning.vue";
import IconBase from "@/components/ui/IconBase.vue";
import SelectEmailBranding from "@/views/deals/components/emails/SelectEmailBranding.vue";
import LfDropdown from "@/components/ui/inputs/LfDropdown.vue";
import { useElementVisibility } from "@vueuse/core";
import uniqBy from "lodash/uniqBy";
import { EmailBrandingId, EmailAliasType } from "@/enums/emailCustomization";
import capitalize from "lodash/capitalize";

const emit = defineEmits(["close-dialog"]);

const store = useEmailsStore();
const { t } = useI18n();
const { showMessage } = useNotification();
const { isTextTruncated } = useElementStatus();

type SendEmailTemplate = Pick<EmailTemplate, "name" | "subject" | "body"> & {
  id?: string;
};
interface EmailFormPayload {
  applications: Record<string, string[]>;
  body: string;
  preferred_email_address: EmailAddress & {
    send_as_bulk: boolean;
  };
  subject: string;
  bcc?: string[];
  cc?: string[];
  email_customization_id?: number;
  email_customization_use_default?: boolean;
  email_template_id?: string;
}

const SENDER_TYPE_MAP = {
  [EmailAliasType.Alias]: t("EMAIL_INTEGRATION.ALIAS"),
  [EmailAliasType.Primary]: t("EMAIL_INTEGRATION.USER_ACCOUNT_EMAIL"),
  [EmailAliasType.Whitelabel]: t("EMAIL_INTEGRATION.SYSTEM_EMAIL")
};

const templateListRef = ref<(HTMLElement | null)[]>([]);
const showSendersDropdown = ref(false);
const sendButtonBlockRef = ref<HTMLElement | null>(null);
const search = ref("");
const templatesTooltip = ref<TippyComponent | null>(null);
const popperOptions = ref<TippyComponent["popperOptions"]>({
  modifiers: [
    {
      name: "flip",
      options: { fallbackPlacements: ["top-start"] }
    }
  ]
});
const body = ref(document.body);
const targetIsVisible = useElementVisibility(sendButtonBlockRef);

const { modalData } = storeToRefs(store);
modalData.value.templates.unshift({
  id: "0",
  name: t("COMMON.SELECT"),
  subject: "",
  body: ""
});
const selectedTemplate = ref<SendEmailTemplate>(
  cloneDeep(modalData.value?.templates?.[0]) || {
    name: "",
    subject: "",
    body: ""
  }
);
const selectedBrandingId = ref<number | null>(null);
const senders = computed(
  () =>
    modalData.value?.email_addresses.reduce(
      (res: Record<string, string>, { value, type }) => {
        const key = isSendAsBulk.value ? type : value;
        res[key] = isSendAsBulk.value ? capitalize(key) : key;
        return res;
      },
      {}
    ) || {}
);

const getInitialSender = () => {
  const alias = modalData.value?.email_addresses?.find(
    ({ type }) => type === EmailAliasType.Alias
  );

  if (alias) {
    return alias;
  }

  const whitelabel = modalData.value?.email_addresses?.find(
    ({ type }) => type === EmailAliasType.Whitelabel
  );

  return whitelabel || null;
};
const sender = ref<EmailAddress | null>(getInitialSender());

const isSendAsBulk = computed(
  () => !!(modalData.value?.applications?.length > 1)
);

const recipientOptions = computed<MultiSelectOption[]>(() =>
  uniqBy(
    modalData.value?.applications?.flatMap(({ owners }) =>
      owners?.map((owner) => ({
        id: String(owner.personal_info_id),
        name: owner.email_address
      }))
    ),
    "id"
  )
);
const selectedRecipients = ref<Pick<MultiSelectOption, "id">[]>(
  recipientOptions.value.map(({ id }) => ({ id }))
);
const ccInput = ref<string | null>(null);
const bccInput = ref<string | null>(null);
const ccEmails = ref<string[]>([]);
const bccEmails = ref<string[]>([]);
const errorMessage = ref<string | null>(null);
const erroredFields = ref<string[]>([]);

const isTemplateOptionTruncated = isTextTruncated(templateListRef);

const ccInputWarning = computed(() => ({
  text: t("ORGANIZATION.EMAIL_TEMPLATES.EMAILS_INPUT_WARNING", {
    inputName: t("ORGANIZATION.EMAIL_TEMPLATES.CC").toUpperCase(),
    emails: filterEmailsInput(ccInput.value)
  }),
  show: !!ccInput.value?.trim().length
}));

const bccInputWarning = computed(() => ({
  text: t("ORGANIZATION.EMAIL_TEMPLATES.EMAILS_INPUT_WARNING", {
    inputName: t("EMAIL_INTEGRATION.BCC").toUpperCase(),
    emails: filterEmailsInput(bccInput.value)
  }),
  show: !!bccInput.value?.trim().length
}));

const filteredTemplatesOptions = computed(() => {
  return modalData.value?.templates.filter(
    (field) =>
      field.name?.toLowerCase().includes(search.value.toLowerCase()) &&
      field.name !== t("COMMON.SELECT")
  );
});

const selectTemplate = (template: SendEmailTemplate) => {
  selectedTemplate.value = structuredClone(toRaw(template));
  hideDropdown();
};

const filterEmailsInput = (inputValue: string | null) =>
  inputValue
    ?.split(",")
    .map((email) => email.trim())
    .filter(Boolean)
    .join(", ");

const handleUpdateSender = (key: string | number) => {
  sender.value =
    modalData.value?.email_addresses.find(({ value, type }) =>
      isSendAsBulk.value
        ? type.toLowerCase() === (key as string).toLowerCase()
        : value === key
    ) || null;
  showSendersDropdown.value = false;
};

const handleUpdateRecipients = (value: Pick<MultiSelectOption, "id">[]) => {
  selectedRecipients.value = value;
};

const handleCcChange = ({
  validEmails,
  input
}: {
  validEmails: string[];
  input: string;
}) => {
  ccInput.value = input;
  ccEmails.value = validEmails;
};

const handleBccChange = ({
  validEmails,
  input
}: {
  validEmails: string[];
  input: string;
}) => {
  bccInput.value = input;
  bccEmails.value = validEmails;
};

const getSenderTypeForIndex = (index: number) => {
  const { type } = modalData.value?.email_addresses[index] || {};
  return SENDER_TYPE_MAP[type] || "-";
};

const { loading, fetchWrapper: sendEmail } = usePromiseWrapper(
  async () => {
    errorMessage.value = null;
    erroredFields.value = [];
    if (
      !sender.value ||
      !selectedRecipients.value.length ||
      !selectedTemplate.value.subject ||
      !selectedTemplate.value.body
    ) {
      erroredFields.value = [
        !sender.value ? "from" : "",
        !selectedRecipients.value.length ? "to" : "",
        !selectedTemplate.value.subject ? "subject" : "",
        !selectedTemplate.value.body ? "body" : ""
      ].filter(Boolean) as string[];
      errorMessage.value = t("ORGANIZATION.EMAIL_TEMPLATES.MISSING_DATA");
      return;
    }

    const recipients = selectedRecipients.value.map((recipient) =>
      recipient.id ? Number(recipient.id) : 0
    );
    const applicationsPayload = modalData.value.applications.reduce<
      Record<string, string[]>
    >((prev, curr) => {
      const applicationRecipients = curr.owners
        .filter(({ personal_info_id }) => recipients.includes(personal_info_id))
        .map(({ email_address }) => email_address);
      prev[curr.id] = applicationRecipients;
      return prev;
    }, {});

    const payload: EmailFormPayload = {
      applications: applicationsPayload,
      subject: selectedTemplate.value.subject,
      body: selectedTemplate.value.body,
      preferred_email_address: {
        ...sender.value,
        send_as_bulk: isSendAsBulk.value
      },
      cc: ccEmails.value.length ? ccEmails.value : undefined,
      bcc: bccEmails.value.length ? bccEmails.value : undefined
    };

    if (selectedBrandingId.value === EmailBrandingId.Default) {
      payload.email_customization_use_default = true;
    } else if (selectedBrandingId.value) {
      payload.email_customization_id = selectedBrandingId.value;
    }

    if (selectedTemplate.value?.id && selectedTemplate.value.id !== "0") {
      payload.email_template_id = selectedTemplate.value.id;
    }

    await emailService.sendApplicationEmail(payload);
    emit("close-dialog");
  },
  {
    finally: () => {
      if (errorMessage.value) {
        showMessage(errorMessage.value, "error");
        return;
      }
    }
  }
);

const hideDropdown = () => {
  templatesTooltip.value?.hide();
};
</script>

<style scoped>
.email-header-row {
  @apply flex w-full items-start space-x-2;

  .left {
    @apply w-1/5 pl-4 font-semibold text-label;
  }

  .right {
    @apply w-4/5 pr-4 relative flex justify-start;
  }
}

.field-warning {
  @apply text-orange-400 absolute top-0-5 -left-5;
}

:deep(.from-dropdown.from-dropdown-single) {
  .input-value {
    @apply text-primary;
  }
}

:deep() {
  .dynamic-field__body {
    @apply h-full;
    > .flex {
      @apply h-full;
      .ql-container {
        max-height: none;
        .ql-editor {
          max-height: none;
          padding-bottom: 3rem;
        }
      }
    }
  }
  .multiselect-dropdown {
    @apply mb-0;
  }

  .send-button {
    @apply px-2 w-[34px];
  }
}
</style>
